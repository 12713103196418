import React from "react";

const GlobalContext = React.createContext({
  uId: null, 
  setUId: () => {},
  logged: false,
  setLogged: () => {},
  emailVerified: false,
  setEmailVerified: () => {},
  client: null,
  setClient: () => {},
  customerId: null,
  setCustomerId: () => {},
  customerInfo: null,
  setCustomerInfo: () => {},
  showSubscription: false,
  setShowSubscription: () => {},
  wrongCrdentials: false,
  setWrongCrdentials: () => {},
  passReset:false,
  setPassReset: () => {},
  logOut: () => {},
  getLogin: () => {},
  getUserInfo: () => {},
  forgotPassword: () => {},
  searchText: null,
  setSearchText: () => {},
  btnDisabled: false,
  setBtnDisabled: () => {},
  showForgoten: false,
  setShowForgoten: () => {},
  getCustomerPortal: () => {},
  showPayment: false,
  setShowPayment: () => {},
  stripeCustomer: null,
  setStripeCustomer: () => {}
});

export default GlobalContext;
