import React, { useState, useEffect, useContext } from "react"
import { loadStripe } from '@stripe/stripe-js';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';
import { database, auth } from "./firebaseConfig"
import { ref, onValue, update, set} from "firebase/database"

import ClientForm from "./ClientForm"

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import "react-multi-carousel/lib/styles.css"

import logoW from './img/logo-w.png'
import profile from './img/profile.jpg'

import GlobalContext from "./context/GlobalContext";
import { BoxArrowRight, CreditCard, Person } from "react-bootstrap-icons"

const stripePromise = loadStripe("pk_test_51OEy0qGYJdAolFWkSSlCTGWmCzV2wau9OVvZi1yXb7ZAR9cyhSRv20FCEDakq0BpBmLpWXbPjZ8E9XeOCbOcfhQE00ZJeAdjcO");

function Footer() {
  const { setShowPayment } = useContext(GlobalContext)
  
  const [ show, setShow ] = useState( false )

  return (
    <>
      <Modal show={ show } size="lg" onHide={ () => setShow(false) }>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#000", paddingLeft: "34px" }}>Aviso de privacidad</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ color: "#000", textAlign: "justify" }}>
          
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={ () => setShow(false) }>
            Aceptar
          </Button>
        </Modal.Footer>
      </Modal>

      <div className='footer'>
      <Container>
        <Row>
          <Col>
            <div align="center">
              <div><img width={ 130 } src={ logoW } /></div>
              <br/>
              <div className="footer-privacy" onClick={ () => setShow( true )}> Aviso de privacidad </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
    </>
  );
}

export default Footer;
