import React, { useState, useEffect, useContext } from "react"
import Carousel from "react-multi-carousel"
import { loadStripe } from '@stripe/stripe-js';
import { database } from "./firebaseConfig"
import { ref, onValue } from "firebase/database"

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal'

import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import "react-multi-carousel/lib/styles.css"

import GlobalContext from "./context/GlobalContext";

const videosRef = ref(database, 'videos/')
const DATE_FORMAT_SHORT = new Intl.DateTimeFormat('es', { year: 'numeric', month: 'short', day: '2-digit', timeZone: (Intl.DateTimeFormat().resolvedOptions().timeZone) })

function Videos() {
  
  const [ videos, setVideos ] = useState([])
  const [ showVideo, setShowVideo ] = useState(false)
  const [ selected, setSelected ] = useState(false)

  useEffect(() => {
    getContentList()
  }, []);

  const getContentList = ( updated ) => {
    console.log("getting info", videosRef)
    
    onValue(videosRef, (snapshot) => {
      console.log("service in")
      let temp = []

      snapshot.forEach(childSnapShot => {
        let keyName = childSnapShot.key;
        let data = childSnapShot.val();
        temp.push({ "key": keyName, "data": data })
      })

      console.log("data", temp)
      setVideos( temp )
    }, {
      onlyOnce: true
    }
    );
  }

  const showPlayer = ( item ) => {
    setSelected(item)
    setShowVideo(true)
  }

  return (
    <div className='App'>

      <Modal show={ showVideo } onHide={ () => setShowVideo(false) } centered>
        <Modal.Header closeButton />
        <Modal.Body>
          {/*<img src="https://drive.google.com/thumbnail?id=1fW0vsgpKoFGCi5NZKvwpLN-YNcNxVgGG&sz=w1000" alt=""/>
          <iframe src="https://drive.google.com/file/d/1fW0vsgpKoFGCi5NZKvwpLN-YNcNxVgGG/preview" width="640" height="480" allow="autoplay" frameborder="0" scrolling="no" seamless=""></iframe>*/}

          <div style={{ width: "640px", height: "480px", position: "relative" }}>
            <iframe src="https://drive.google.com/file/d/1fW0vsgpKoFGCi5NZKvwpLN-YNcNxVgGG/preview"   width="640" height="480" frameborder="0" scrolling="no" seamless=""></iframe>
            <div style={{ width: "80px", height: "80px", position: "absolute", opacity: "0", right: "0px", top: "0px" }}> </div>
          </div>
        </Modal.Body>
      </Modal>
      
      <div style={{ height: '106px' }} />
      <div>
        <Container>
          <Row>
            <Col>
              <div className="section-title txt-center">
                Videos
              </div>
              <div className="section-desc txt-center">
                Encuentra el plan que más se adapte a tus necesidades. 
              </div>
              <div style={{ height: '50px' }} />
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col md={ 12 } align="center">
              { videos.length > 0 && 
                <div className="list-video-main" style={{ maxWidth: '636px' }} onClick={ () => showPlayer(videos[0].data) }>
                  <div align="left">
                    <div> <img className="list-preview" src={ videos[0].data.preview } /> </div>
                    <div className="list-title">{ videos[0].data.title }</div>
                    <div className="list-date">{ DATE_FORMAT_SHORT.format((new Date( videos[0].data.date )).getTime()) }</div>
                    <div className="list-duration">{ videos[0].data.duration }</div>
                  </div>
                </div>
              }
            </Col>
            <Col md={ 12 }>
              <Container>
                <Row align="left">
                  { videos.slice(1, videos.length).map( ({ data }) => (
                    <Col md={ 3 } className="list-video" onClick={ () => showPlayer(data) }>
                      <div> <img className="list-preview" src={ data.preview } /> </div>
                      <div className="list-title">{ data.title }</div>
                      <div className="list-date">{ DATE_FORMAT_SHORT.format((new Date(data.date)).getTime()) }</div>
                      <div className="list-duration">{ data.duration }</div>
                    </Col>
                  )) }
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>

      </div>
      
    </div>
  );
}

export default Videos;
