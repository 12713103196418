import React, { useRef } from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import Login from "./Login"
import Main from './Main'
import Live from './Live'
import Videos from './Videos'
import Header from './Header'
import Footer from "./Footer"

function App() {

  const refUs = useRef()
  const refPrices = useRef()
  
  return (
    <div>
      
      { !window.location.pathname.includes('/login') && 
        <Header refUs={ refUs } refPrices={ refPrices } />
      }
      
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main refUs={ refUs } refPrices={ refPrices } />} />
          <Route path="login" element={<Login />} />
          <Route path="onlive" element={<Live />} />
          <Route path="videos" element={<Videos />} />
        </Routes>
      </BrowserRouter>
      
      <Footer />
      
    </div>
  );
}

export default App;
