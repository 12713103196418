import React, { useState } from "react"

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Carousel from 'react-bootstrap/Carousel'

import './App.css'

import isra1 from './img/isra-1.png'
import isra2 from './img/isra-2.png'
import isra3 from './img/isra-3.png'
import isra4 from './img/isra-4.png'
import isra5 from './img/isra-5.png'

function Us() {
  const [ carouselActiveItem, setCarouselActiveItem ] = useState(0)

  const onChangeSlider = ( e ) => {
    setCarouselActiveItem ( e )
  }
  
  return (
    <div>
      
      <Container>
          <Row className="align-items-center">
            <Col md={ 7 }>
              <div className="us-container" align="left">
                <div className="section-title txt-left">
                  ¿Quién es Israel Nochebuena?
                </div>

                <div className="c-text txt-left">
                  <Carousel activeIndex={ carouselActiveItem } interval={ null } pause={ false } controls={ false }>
                    <Carousel.Item>
                      <div><b>Israel Nochebuena (Veracruz, Méx. 1977)</b></div>
                      <div>Acompañante en Bioneuroemoción egresado del Enric Corbera Institute, pertenece al Colegio de Consultores y Comunicadores de la Semiología de la Vida Cotidiana, por lo cual ha dirigido retiros y talleres de desarrollo humano desde la perspectiva de la Bioneuroemoción y la Semiología.</div>
                    </Carousel.Item>
                    <Carousel.Item>
                      <div>Instructor certificado en Kundalini Yoga y Meditación además de haber trabajado alrededor de 20 años con personas con problemas y dependencia a substancias, desarrollando un taller basado en Kundalini Yoga y los principios de AA que se llamó: “Tratando la Neurosis y las Adicciones con Kundalini Yoga”.</div>
                    </Carousel.Item>
                    <Carousel.Item>
                      <div>Además de haber realizado otros estudios en medicinas alternativas y complementarias como lo es el Biomagnetismo Médico, que estudió en la universidad de Chapingo con el Doc. Isaac Goiz Durán y la Iridología con el Doc. Siegfried Baumbach en Iztac entre otras, con Daniel Prieto ha profundizado intensamente en La Voz Humana, trabajo que permite tener una experiencia real consigo mismo y no con el imaginario o cualquier otra fantasía generada por la mente.</div>
                    </Carousel.Item>
                    <Carousel.Item>
                      <div>También cuenta con un lugar llamado “Centro de Yoga el Árbol de la Vida”, en donde se han realizados talleres presenciales, que se encuentra en el campo a las afueras de Cuautitlán Izcalli, lo cual permite trabajar de manera muy directa y sin distracciones, en un espacio rodeado de naturaleza.</div>
                    </Carousel.Item>
                    <Carousel.Item>
                      <div>Israel ha sido un buscador incansable que nunca ha estado satisfecho con los paradigmas ya establecidos socialmente, por eso su visión de vida ha estado manifestada en su frase favorita “Mi religión es vivir y morir sin remordimiento”.</div>
                    </Carousel.Item>
                  </Carousel>
                </div>
              </div>
            </Col>
            <Col md={ 5 }>
              <Carousel indicators={ false } interval={ 10000 } pause={ true } onSlide={ (e) => onChangeSlider( e ) }>
                <Carousel.Item>
                  <div> <img src={ isra1 } className="c-img"/> </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div> <img src={ isra2 } className="c-img"/> </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div> <img src={ isra3 } className="c-img"/> </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div> <img src={ isra4 } className="c-img"/> </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div> <img src={ isra5 } className="c-img"/> </div>
                </Carousel.Item>
              </Carousel>
            </Col>
          </Row>
        </Container>
      
    </div>
  );
}

export default Us;
