import { useState, useEffect, useContext } from 'react'

import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'

import './App.css'
import { Facebook, Google } from 'react-bootstrap-icons'

import logo from './img/logo.png'

import GlobalContext from "./context/GlobalContext"

var url_string = window.location.href
var url = new URL(url_string)
var pid = url.searchParams.get("pid")

function Live() {
  const { logged, stripeCostumer } = useContext(GlobalContext);
  const [ activeSubscription, setActiveSubscription ] = useState(null);

  useEffect(() => {    
    if(stripeCostumer)
      setActiveSubscription( stripeCostumer.subscriptions.data[0].items.data[0].price.id )
  }, [stripeCostumer]);

  return (
    <div className="">
      <div style={{ height: '106px' }} />
      { logged &&
        <div align="center ">
          <Container>
            <Row>
              <Col md={ 9 }>
                <div style={{ position: 'relative' }}>
                  <iframe width="100%" height="809" src="https://www.youtube.com/embed/Zi3YQnbigbE" title="Transmision de prueba" frameborder="0" allow="accelerometer; autoplay;" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                  <div style={{ width: "100%", height: "80px", position: "absolute", opacity: "0", right: "0px", top: "0px" }}> </div>
                  <div style={{ width: "100%", height: "80px", position: "absolute", opacity: "0", left: "0px", bottom: "0px" }}> </div>
                </div>
              </Col>
              <Col md={ 3 }>
                { activeSubscription != 'price_1P61F7GYJdAolFWk0mAc0ogQ' ? 
                  <iframe width="100%" height="360" src="https://www.youtube.com/live_chat?v=Zi3YQnbigbE&embed_domain=sv.digital-eureka.com"></iframe>
                  : 
                  <div className='chat-text'> <br/> El Chat esta desactivado. Para tener acceso a él, actualiza tu plan de suscripción y beneficiate de mas servicios que tenemos para ti. </div>
                }
              </Col>
            </Row>
          </Container>
          <br/><br/>
        </div>   
      }

    </div>
  );
}

export default Live;
